import React from "react"
import Layout from "../components/layout";
import { Container } from "@material-ui/core";
import { PageHeader } from "../components/PageHeader";
import styled from "@emotion/styled";
import whyImage from "../images/why/why.jpg"

const ImageWrapper = styled.div`

  text-align: center;

  @media (min-width: 600px) {
    float: left;
    margin: 0.5rem 1.5rem 0.5rem 0
  }
  
`;

const IndexPage = () => {

  return (
    <Layout>
      <Container maxWidth="md">

        <PageHeader>
          Why
        </PageHeader>

        <ImageWrapper>
          <img src={whyImage} alt={"why"} width={200} height={204}/>
        </ImageWrapper>

        <p>
          Ich schreibe gerne und viel und das ähnlich, wie meine Romanfigur aus "Endzeitromantick",
          seit meinem 12. Lebensjahr. Ich schreibe über mich, über dich, über die Gesellschaft,
          in der wir Leben müssen. Aber müssen wir das wirklich?
        </p>

        <p>
          Ich lebe seit vielen Jahren in einer Beziehung, um diese und meine Privatsphäre zu schützen,
          schreibe und veröffentliche ich meine Texte und Bücher unter dem Pseudonym Jack Sleepwalker.
          Warum? Ich stelle gerne Fragen und alles in Frage, was so ist, wie es ist, ohne so sein zu müssen,
          wie man mich will.
        </p>

        <p>
          Theater, Kunst, Psychologie und Literatur sind meine Drogen, um nicht verrückt zu werden,
          in einer Welt, die mir häufig wenig gefällt.
        </p>

        <p>
          In meinem neuen Leben, arbeite ich als freischaffender Künstler und Autor im Bereich LGBTIQ*,
          engagiere mich ehrenamtlich für die Belange und Rechte aller Menschen des LGBTIQ*.
        </p>

        <p>
          Ich bin das B, das T und das nonbinäre Q* mit männlichem Pronomen.
        </p>

        <p>
          Ich bin Jacks Rache, in dem ich lache und das hier mache.
        </p>

        <p>
          Es ist keine hohe Kunst, aber sie macht uns sichtbar, als ein Teil des Ganzen, welches ohne uns
          nie ganz sein wird.
        </p>

        <p>
          In meinem alten Leben, arbeitete ich zuletzt als Diplom Sozialwissenschaftler in verschiedenen
          Bereichen der Sozialpsychiatrie, sowie als Dozent und Trainer an Fachhochschulen.
          Ferner leitete ich eine Theatergruppe für Menschen mit psychischen Erkrankungen.
        </p>

      </Container>
    </Layout>
  );

};

export default IndexPage
